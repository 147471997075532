import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const PreschoolVideo = "https://player.vimeo.com/video/532759785"
const PreschoolGuide =
  "https://cloud-cdn.thinkorange.com/individual_file_previews/Orange_Store/Curriculum_Subscriptions/First_Look/Premium_Media_Package/curriculum/202104/08_Preschool_OnlineExperience/Week_2/2104_Preschool_Parent_Guide_W2/2104_Preschool_Parent_Guide_W2.pdf"
const KidsVideo = "https://player.vimeo.com/video/532762754"
const KidsGuide =
  "https://cloud-cdn.thinkorange.com/individual_file_previews/Orange_Store/Curriculum_Subscriptions/252_Kids/Premium_Media_Package/curriculum/202104/12_Kids_OnlineExperience/Week_2/2104_Kids_Parent_Guide_W2/2104_Kids_Parent_Guide_W2.pdf"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  &:empty {
    display: none;
  }
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    text-align: center;
  }
`

const KidsContent = () => (
  <div>
    <HeaderText>Preschool Video</HeaderText>
    <ParagraphText>
      <div
        css={css`
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
        `}
      >
        <iframe
          src={PreschoolVideo}
          width="560"
          height="349"
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          `}
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
    </ParagraphText>
    <HeaderText>
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <MainButton href={PreschoolGuide} target="_blank">
          Preschool Parent Guide
        </MainButton>
      </div>
    </HeaderText>
    <HeaderText
      css={css`
        margin-top: 3rem;
      `}
    >
      Kids Video
    </HeaderText>
    <ParagraphText>
      <div
        css={css`
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
        `}
      >
        <iframe
          src={KidsVideo}
          width="560"
          height="349"
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          `}
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <HeaderText>
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <MainButton href={KidsGuide} target="_blank">
            Kids Parent Guide
          </MainButton>
        </div>
      </HeaderText>
    </ParagraphText>
  </div>
)

export default KidsContent
