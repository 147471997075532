import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/resources_big.jpeg"
import AppStore from "../../images/appStore.png"
import GooglePlay from "../../images/GooglePlay.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import KidsContent from "../../components/kidsContent"
import AmazonButton from "../../images/amazon-button.png"
import SEO from "../../components/seo"

const BadgeImage = styled.img`
  width: 160px;
  margin-top: 2rem;
  margin-right: 1rem;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 1.5rem;
  font-size: 1.5rem;
`

const MainButton = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-top: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  &:empty {
    display: none;
  }
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    text-align: center;
  }
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Parent Resources"
    SecondText="Helpful links, tools, and tips for all parents"
  >
    <SEO title="Cross Church Parent Resources" />
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Parent Cue App
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      As the spiritual leader for your kids, we want to do everything we can to
      equip you to love and lead them to Jesus. The Parent Cue app is a great
      resource to connect with what your child is learning in Cross Church Kids
      as well as ideas for you to connect as a family in the morning, at meal
      times, during drive time, and at bedtime. Download the app here:
    </ParagraphText>
    <div
      css={css`
        display: flex;
      `}
    >
      <a href="https://itunes.apple.com/us/app/parent-cue/id433066482?mt=8">
        <BadgeImage src={AppStore} alt="Apple Store Icon" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=org.rethinkgroup.parentcuepaid&hl=en">
        <BadgeImage src={GooglePlay} alt="Google Play icon" />
      </a>
    </div>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Bible App for Kids
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Through interactive adventures and beautiful animations, kids explore the
      big stories of the Bible. The Bible App for Kids is a delight-filled
      experience designed to encourage kids to return again and again. It’s the
      beginning of a lifelong love of God’s Word. Download the app here:
    </ParagraphText>
    <div
      css={css`
        display: flex;
      `}
    >
      <a href="https://apps.apple.com/us/app/bible-app-for-kids/id668692393">
        <BadgeImage src={AppStore} alt="Apple Store Icon" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.bible.kids&hl=en_US&gl=US">
        <BadgeImage src={GooglePlay} alt="Google Play icon" />
      </a>
    </div>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Start Here Class Sign-Ups
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      If your kids 6th grade and under are ready for baptism or asking questions
      about salvation, sign them up for our next Start Here class.
    </ParagraphText>
    <br />
    <MainButton href="https://crosschurchcares.churchcenter.com/registrations/events/1191938">
      Sign-Up for next Start Here Class
    </MainButton>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Amazon Resources List
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Check out our Amazon list for parenting books, tools, and more.
      <br />
      <a href="https://www.amazon.com/hz/wishlist/ls/1PMQO9ABF6WCF?ref_=wl_share">
        <BadgeImage src={AmazonButton} alt="Amazon list resource link" />
      </a>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Podcasts
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Some of our favorite podcasts for kids and parents.
      <br />
      <div
        css={css`
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
        `}
      >
        <MainButton href="https://podcasts.apple.com/us/podcast/1-year-daily-audio-bible-for-kids/id300620366">
          1 Year Daily Audio Bible for Kids
        </MainButton>

        <MainButton href="https://podcasts.apple.com/us/podcast/gods-big-story/id1497921615">
          God's Big Story
        </MainButton>

        <MainButton href="https://podcasts.apple.com/us/podcast/parent-cue-live/id1013652909">
          Parent Cue Live
        </MainButton>
      </div>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Extra Resources
    </PageHeaders>
    <HeaderText>Dove.org</HeaderText>
    <ParagraphText>
      Resource for parents to check out what content is in a movie before
      watching it.
    </ParagraphText>
    <br />
    <MainButton href="https://dove.org">Check out Dove.Org</MainButton>
    <HeaderText
      css={css`
        margin-top: 2rem;
      `}
    ></HeaderText>{" "}
    <HeaderText>Feed</HeaderText>
    <ParagraphText>
      Stats about the state of faith for teens in the U.S. and resources to help
      them.
    </ParagraphText>
    <br />
    <MainButton href="https://feed.bible/research/">Check out Feed</MainButton>
    <HeaderText
      css={css`
        margin-top: 2rem;
      `}
    ></HeaderText>
    <HeaderText>Plugged In Online</HeaderText>
    <ParagraphText>
      Helpful content for students/kids/parents for online
      entertainment/content.
    </ParagraphText>
    <br />
    <MainButton href="https://www.pluggedin.com/">
      Check out Plugged In
    </MainButton>
  </Layout>
)

export default IndexPage
